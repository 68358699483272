<template>
  <div class="h-100 bg-info">
    <div
      v-if="chats.length > 0"
      class="chats px-2"
    >
      <template
        v-for="(chat, index) in chats"
      >
        <div
          v-if="index === 0 || $formatDate(chats[index -1].created_at) !== $formatDate(chat.created_at)"
          :key="`date-${chat.key}`"
          class="text-center mt-1"
        >
          <small> {{ $formatDate(chat.created_at) }} </small>
        </div>

        <div
          v-if="chat.sender_id === 'system'"
          :key="chat.key"
          class="mt-1 w-100 text-center font-weight-bold"
        >
          {{ chat.message }}
        </div>

        <div
          v-else
          :key="chat.key"
          class="chat"
          :class="{'chat-left': chat.sender_id !== $store.state.auth.ActiveUser.email}"
        >
          <div class="chat-body">
            <div
              class="chat-content"
            >
              <template v-if="chat.sender_id !== $store.state.auth.ActiveUser.email_address ">
                <p
                  :class="`chat-content-header font-weight-bolder ${chat.sender_id !== $store.state.auth.ActiveUser.email_address ? 'text-success' : ''}`"
                >
                  {{ !$isEmpty(participants[chat.sender_id]) ? participants[chat.sender_id]['fullname'] : 'bot' }}
                </p>
                <hr class="mx-0 mt-0 p-0" style="margin-bottom: 0.3em">
              </template>

              <p style="white-space: pre-line">{{ chat.message }}</p>
              <small style="font-size: 0.6em;"> {{ $formatTime(chat.created_at) }} </small>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chats: { type: Array, default: () => [] },
    sender: { type: Object, default: () => {} },
    recipient: { type: Object, default: () => {} },
    issue: { type: Object, default: () => {} },
  },
  data() {
    return {
      participants: {},
    }
  },
  watch: {
    chats(val) {
      val.forEach(chat => {
        if (!(chat['sender_id'] in this.participants) && chat['sender_id'] !== 'bot') {
          this.get_participant_details(chat['sender_id'])
        }
        if (!(chat['recipient_id'] in this.participants) && chat['recipient_id'] !== 'bot') {
          this.get_participant_details(chat['recipient_id'])
        }
      })
    },
  },
  updated() {
    // this.chats.forEach(chat => {
    //   if (!(chat['sender_id'] in this.participants) && chat['sender_id'] !== 'bot') {
    //     this.get_participant_details(chat['sender_id'])
    //   }
    //   if (!(chat['recipient_id'] in this.participants) && chat['recipient_id'] !== 'bot') {
    //     this.get_participant_details(chat['recipient_id'])
    //   }
    // })
  },
  methods: {
    get_participant_details(email) {
      if (!this.$isEmpty(email)) {
        this.$http
          .get(`/api/users/${email}/basic-info`, {
            form_data: this.issue,
          })
          .then(response => {
            const { success, output } = response.data

            if (success) {
              this.participants[output['email']] = output
            }
          })
          .catch(() => {})
      }
    },

    
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";

.chats {
  .chat-avatar {
    float: right;
  }
  .chat-body {
    display: block;
    margin: 10px 0 0 0;
    overflow: hidden;
    .chat-content {
      min-width: 7em;
      float: right;
      text-align: left;
      padding: 0.7rem 1rem;
      margin: 0 1rem 10px 0;
      clear: both;
      color: $white;
      background: $primary;
      // background-color: lighten($primary, 88%);
      // @include gradient-directional(map-get($primary, 'base'), map-get($primary, 'lighten-2'), 80deg);
      border-radius: 12px;
      box-shadow: 0 4px 8px 0 rgba($black, 0.12);
      max-width: 75%;

      .chat-content-header {
        text-align: right;
        color: $success;
      }
      p {
        margin: 0;
      }
      small {
        float: right;
      }
    }
  }
  // Chat area left css
  .chat-left {
    .chat-avatar {
      float: left;
    }
    .chat-body {
        margin: 10px 0 0 0;

      .chat-content {
        min-width: 7em;
        float: left;
        text-align: left;
        margin: 0 0 10px 1rem;
        color: $black;
        background: none;
        background-color: lighten($white, 18%);

        .chat-content-header{
          text-align: left;
        }
        small {
          float: right;
        }
      }
    }
  }
}

</style>